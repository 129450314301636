import { mapGetters } from 'vuex';

export default {
	data () {
		return {
			selectedNewsSources: [],
			newNewsSource: {
				title: ''
			},
			errorMessages: []
		}
	},
	computed: {
		...mapGetters({
			newsletters: ['newsletter/newsletters'],
			isLoadingResources: 'isLoadingResources'
		})
	},
	methods: {
		downloadNewsletter (newsletter) {
			this.$store.dispatch('newsletter/downloadNewsletter', newsletter.id).then(response => {
				let blob = new Blob([response.body]);
				let link = document.createElement("a");
				link.href = window.URL.createObjectURL(blob);
				link.setAttribute("download", `${newsletter.newsletter_nr}.html`);
				link.click();
				URL.revokeObjectURL(link.href);
			})
		},
		deleteNewsletter (newsletter) {
			this.$store.dispatch('newsletter/deleteNewsletter', newsletter.id).then(() => {
				this.$notify({
					title: 'Nieuwsbrief verwijderd',
					message: 'Nieuwsbrief is succesvol verwijderd!',
					type: 'success',
					position: 'bottom-right'
				})
			});
		}
	},
	created () {
		this.$store.dispatch('newsletter/fetchNewsLetters')
	}
}